import { PixelCrop } from "react-image-crop";

export const utilCropToBlob = async (pixelCrop: PixelCrop, image: HTMLImageElement) => {
  if (!pixelCrop || !image) return;

  try {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropWidth = Math.round(pixelCrop.width * scaleX);
    const cropHeight = Math.round(pixelCrop.height * scaleY);
    const cropX = Math.round(pixelCrop.x * scaleX);
    const cropY = Math.round(pixelCrop.y * scaleY);

    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx = canvas.getContext("2d", {
      alpha: true,
      willReadFrequently: true,
      desynchronized: false,
    });

    if (!ctx) return;

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    const blob = await new Promise<Blob>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          resolve(blob);
        },
        "image/jpg",
        1.0
      );
    });

    return URL.createObjectURL(blob);
  } catch (error: any) {
    console.error("이미지 크롭 중 오류 발생:", error);
    throw error;
  }
};
