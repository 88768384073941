/* eslint-disable */
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import ReactCrop, { PercentCrop, PixelCrop } from "react-image-crop";
import { useNavigate, useParams } from "react-router";
import { ArrowBackIos, RotateLeft, RotateRight } from "@material-ui/icons";
import "react-image-crop/dist/ReactCrop.css";
import constants from "../../asset/constants";
import { CommonContext, PaymentContext } from "../../asset/context";
import { getUser } from "../../asset/storage";
import store from "../../asset/store";
import style from "../../asset/style";
import "./UiCrop.css";
import { BUTTON_SECTION_STYLES, StyleKey } from "./cropButtonSection_style.constant";
import { utilCropToBlob } from "./cropToBlob.util";
import { useSearchParams } from "react-router-dom";
import apiFn from "../../asset/apiClass";
import { utilCreateFileToString } from "./createFileToString.util";

const initState = {
  imageUri: "",
};

function rotateReducerAction(state, action) {
  switch (action.type) {
    case "SET_ROTATED_IMAGE": {
      return { ...state, imageUri: action.payload };
    }
    case "INIT_IMAGE_URL": {
      return { ...state, imageUri: action.payload.imageUri };
    }
    default:
      return state;
  }
}

export default function App({ setFileObj, fileObj, qnaData, setIsCrop, addSearch }) {
  let params = useParams();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userInfo = getUser();

  const [isDevicePortrait, setIsDevicePortrait] = useState(false);
  const [imageUriState, dispatchImageUriState] = useReducer(rotateReducerAction, initState);

  const imgRef = useRef<HTMLImageElement>(null);

  const [currentPercentCrop, setCurrentPercentCrop] = useState<PercentCrop>();
  const [currentPixelCrop, setCurrentPixelCrop] = useState<PixelCrop>();

  const { setFileObj: saveImageData } = useContext(CommonContext);

  const classroomId = constants.classroomId;

  const { paymentOpen } = useContext(PaymentContext);

  function imageFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      console.error("파일이 없어요.");
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const result = reader.result as string;
      dispatchImageUriState({ type: "INIT_IMAGE_URL", payload: { imageUri: result } });
      URL.revokeObjectURL(result);
    });
    reader.readAsDataURL(file);
  }

  const onRotateImage = (imageUri: string, rotate: number) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUri;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d", {
          alpha: true,
          willReadFrequently: true,
          desynchronized: false,
        });

        if (!ctx) {
          reject(new Error("캔버스 컨텍스트 생성 실패"));
          return;
        }

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";

        const radian = (rotate * Math.PI) / 180;
        const sin = Math.abs(Math.sin(radian));
        const cos = Math.abs(Math.cos(radian));

        canvas.width = Math.round(img.width * cos + img.height * sin);
        canvas.height = Math.round(img.height * cos + img.width * sin);

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(radian);
        ctx.translate(-img.width / 2, -img.height / 2);

        ctx.drawImage(img, 0, 0);

        const rotatedImage = canvas.toDataURL("image/jpeg", 0.95);
        canvas.width = 0;
        canvas.height = 0;
        img.onload = null;
        img.onerror = null;
        img.src = "";
        URL.revokeObjectURL(imageUri);
        resolve(rotatedImage);
      };

      img.onerror = (error) => {
        img.onload = null;
        img.onerror = null;
        reject(error);
      };
    });
  };

  const handleRotate = async (rotate: number) => {
    try {
      const rotatedImage = await onRotateImage(imageUriState.imageUri, rotate);
      dispatchImageUriState({ type: "SET_ROTATED_IMAGE", payload: rotatedImage });
    } catch (error) {
      console.error("이미지 회전 중 오류 발생:", error);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { clientWidth, clientHeight } = e.target as HTMLImageElement;
    setCurrentPercentCrop({
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    setCurrentPixelCrop({
      unit: "px",
      x: 0,
      y: 0,
      width: clientWidth,
      height: clientHeight,
    });
  }

  const apiSaveImageStorage = async (file: File) => {
    await apiFn.uploadFile(`${params["who"]}/${userInfo.id}` + ".jpg", file);
  };

  const createQuestionNextStep = async (imageStringData: string, imageFileData: File) => {
    if (params?.type === "set") {
      goBackUri();
    } else if (["se", "qnaRegi"].includes(params?.type)) {
      const baseRoute = `/searchresult/student/${params.who}`;
      if (qnaData.tid || qnaData.bid) {
        setFileObj({
          ...fileObj,
          isImg: true,
          imageFile: imageFileData,
        });
        // tid가 있고, classroomId가 없으며 paymentOpen이 "select"인 경우
        if (qnaData.tid && !classroomId && paymentOpen === "select") {
          alert("질문권 질문이 준비중입니다.");
        }

        if (qnaData.tid) {
          navigate(`${baseRoute}/b`); // 교재 선택 화면
        } else {
          navigate(`${baseRoute}/t`); // 강사 선택 화면
        }
      } else {
        // tid와 bid 모두 없는 경우
        setFileObj({
          ...fileObj,
          isImg: true,
          base64: imageStringData,
        });
        navigate(`/searchresult/${params.who}/de/de`);
      }
    }
  };

  const searchImageNextStep = (imageData: string) => {
    saveImageData({ ...fileObj, base64: imageData });
    if (params.type === "bookImageSearch") {
      navigate(`/search/question/book/${addSearch?.bid}/image`);
    } else navigate("/search/question/image");
  };

  const goBackUri = () => {
    const stack = store.get("stack");
    if (stack.length > 0) navigate(-1);
    else navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDevicePortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(fileObj).length === 0) {
      goBackUri();

      return;
    }
    imageFileSelect(fileObj["file"]);
  }, []);

  const getStyle = (styleKey: StyleKey) => {
    const orientation = isDevicePortrait ? "portrait" : "unPortrait";
    return BUTTON_SECTION_STYLES[orientation][styleKey];
  };

  const handleSuccessButtonClick = async () => {
    const cropImageBase64 = await utilCropToBlob(currentPixelCrop, imgRef.current);
    const makeFile = await utilCreateFileToString(cropImageBase64, "cropImage.jpg");
    if (searchParams.get("myInfo")) {
      await apiSaveImageStorage(makeFile);
      setFileObj({});
      goBackUri();
    } else if (["d", "rectangle"].includes(params?.type)) {
      setFileObj({
        ...fileObj,
        base64: makeFile,
      });
      goBackUri();
    } else if (["set", "se", "qnaRegi"].includes(params?.type)) {
      await createQuestionNextStep(imageUriState.imageUri, makeFile);
    } else if (["searchImage", "bookImageSearch"].includes(params?.type)) {
      searchImageNextStep(imageUriState.imageUri);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 0,
          padding: "15px 15px",
          zIndex: 1,
        }}
      >
        <ArrowBackIos
          style={{ color: "white" }}
          onClick={() => {
            if (setIsCrop) {
              //TODO profile 수정에서 setIsCrop을 사용하지 않게하고 지우기,
              //TODO: setFileObj 꼭! 개선하기
              setFileObj({});
              setIsCrop(false);
            } else {
              const stack = store.get("stack");
              if (stack.length > 0) navigate(-1);
              else navigate("/");
            }
          }}
        />
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {params["type"] == "se"
            ? "사진으로 해답 찾기"
            : params["type"] == "qnaRegi"
              ? "문제 등록하기"
              : "이미지 편집"}
        </div>
      </div>
      {params["type"] == "set" ? (
        <></>
      ) : params["type"] == "se" || params["type"] == "qnaRegi" ? (
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "rgba(255,255,255,0.8)",
            display: "flex",
            justifyContent: "center",
            flex: 1,
            width: "100%",
            position: "absolute",
            top: 100,
            zIndex: 99999999999999999,
            left: 0,
            padding: "10px 0px",
          }}
        >
          문항을 정방향으로 회전하여 한문제만 영역설정해 주세요.
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          width: "100vw",
          height: isDevicePortrait ? "80vh" : "100vh",
          display: "flex",
          marginTop: isDevicePortrait ? "70px" : "0px",
          alignItems: "center",
          justifyContent: isDevicePortrait ? "center" : "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactCrop
            style={{
              maxHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isDevicePortrait ? "0px" : "70px",
              overflow: "visible",
            }}
            crop={currentPercentCrop}
            onChange={(_, percentCrop) => setCurrentPercentCrop(percentCrop)}
            onComplete={(c) => setCurrentPixelCrop(c)}
          >
            <img ref={imgRef} alt="Crop me" src={imageUriState.imageUri} onLoad={onImageLoad} />
          </ReactCrop>
        </div>

        <div style={getStyle("mainContainer")}>
          <div style={getStyle("subContainer")}>
            <RotateLeft
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                handleRotate(-90).catch((error: any) => {
                  alert("이미지 회전에 실패했습니다.");
                  console.error(error);
                });
              }}
            />
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: style.common.borderColor,
                fontSize: 14,
                height: "44px",
                cursor: "pointer",
                outline: "none",
                margin: "7.5px 0px",
                color: "white",
                width: "60px",
                backgroundColor: style.common.t_color,
                borderRadius: 20,
              }}
              onClick={async () => {
                await handleSuccessButtonClick();
              }}
            >
              {params["type"] == "se" ? "검색" : params["type"] == "qnaRegi" ? "등록" : "확인"}
            </button>
            <RotateRight
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                handleRotate(90).catch((error: any) => {
                  alert("이미지 회전에 실패했습니다.");
                  console.error(error);
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
